<template>
  <div v-if="logoSrc && imageFound">
    <img 
      class="right-logo"
      :src="logoSrc" 
      @error="imageFound = false"
    />
  </div>  
</template>

<script>
export default {
  name: 'Logo',
  data() {
    return {
      imageFound: true
    };
  },
  props: {
    contractCode : {
      type: Number
    }
  },
  computed: {
    logoSrc () {
      let src = '';

      if (this.$store.state.Logo.logo) {
        src = 'data:image/png;base64, ' + this.$store.state.Logo.logo;
      }
      else if (this.contractCode) {
        src = `/images/${this.contractCode}.png`;
      }

      return src;
    }
  }
};
</script>

<style lang="scss" scoped>
.right-logo {
  overflow: hidden;
  height: auto;
  width: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

</style>
