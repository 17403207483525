import { mapGetters } from 'vuex';

export const googletagmanager = {
  data() {    
    return {
      googleTagManagerInjected: false,
    };
  },
  mounted() {
    if (!this.googleTagManagerInjected && this.labelSettings.label === "aev") {
      const script = document.createElement('script');
      script.src = '/js/googleTagManagerScript.js'
      script.type = 'text/javascript'

      document.head.appendChild(script);

      this.googleTagManagerInjected = true;
    }    
  },
  computed: {
    ...mapGetters(['labelSettings', 'language']),
  },
};
