<template>
  <div>
    <div v-if="contractedType === Contracted">
      <Compensated />
      <PlainText class="d-inline margin-left">{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.contracted', { inserts: { year: year } }) }}</PlainText>
      <Tooltip class="d-inline">
        <InformationIcon class="margin-left" />
        <template #content>
          <ul>
            <li v-for="item in $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.contractedTooltipText.items')" :key="item">
              <BulletPoint />
              <PlainText class="d-inline">{{ item }}</PlainText>
            </li>
          </ul>
        </template>
      </Tooltip>
    </div>
    <div v-else-if="contractedType === Negotiating">
      <NotYet />
      <PlainText class="d-inline margin-left">{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.negotiating', { inserts: { year: year } }) }}</PlainText>
      <Tooltip class="d-inline">
        <InformationIcon class="margin-left" />
        <template #content>
          <ul>
            <li v-for="item in $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.negotiatingTooltipText.items')" :key="item">
              <BulletPoint />
              <PlainText class="d-inline">{{ item }}</PlainText>
            </li>
            <ul class="nested-list-style">
              <li v-for="extraItem in $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.negotiatingTooltipText.extraItems')" :key="extraItem" class="nested-list-items">
                <BulletPoint />
                <PlainText class="d-inline">{{ extraItem }}</PlainText>
              </li>
            </ul>
          </ul>
        </template>
      </Tooltip>
    </div>
    <div v-else-if="contractedType === NotContracted">
      <NotCompensated />
      <PlainText class="d-inline margin-left">{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.notContracted', { inserts: { year: year } }) }}</PlainText>
      <Tooltip class="d-inline">
        <InformationIcon class="margin-left" />
        <template #content>
          <ul>
            <li v-for="item in $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.notContractedTooltipText.items')" :key="item">
              <BulletPoint />
              <PlainText class="d-inline">{{ item }}</PlainText>
            </li>
            <ul class="nested-list-style">
              <li v-for="extraItem in $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.notContractedTooltipText.extraItems')" :key="extraItem" class="nested-list-items">
                <BulletPoint />
                <PlainText class="d-inline">{{ extraItem }}</PlainText>
              </li>
            </ul>
          </ul>
        </template>
      </Tooltip>
    </div>
  </div>
</template>
    
<script>
import ContractType from '@/constants/ContractType';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import Compensated from '@/assets/icons/Compensated.vue';
import InformationIcon from '@/assets/icons/InformationIcon.vue';
import NotCompensated from '@/assets/icons/NotCompensated.vue';
import NotYet from '@/assets/icons/NotYet.vue';
import Tooltip from '@/components/shared/2.0-import/Tooltip.vue';
import BulletPoint from '@/assets/icons/BulletPoint.vue';

export default {
  name: 'HealthcareProviderContracted',
  components: {
    PlainText,
    Compensated,
    InformationIcon,
    NotCompensated,
    NotYet,
    Tooltip,
    BulletPoint
  },
  data() {
    return {
      Contracted: ContractType.Contracted,
      Negotiating: ContractType.Negotiating,
      NotContracted: ContractType.NotContracted
    };
  },
  props: {
    contractedType: null,
    year: {
      required: true,
      type: Number
    }
  }
};
</script>
    
<style scoped lang="scss" >
.margin-left {
  margin-left: 7px;
}
#info-icon {
  color: var(--color-primary);
  height: 24px;
}
ul {
  list-style: none;
  li {
    display: flex;
    flex-direction: row;
    gap:8px;
    color: var(--color-primary-800);
    margin-bottom: 4px;
    :first-child {
      margin-top: 8px;
      flex-shrink: 0;
    }
    &.nested-list-items {
      gap: 20px;
    }
  }
}
ul .nested-list-style {
  padding-left: 20px;
}
</style>