<template>
  <Container>
    <!-- Giving each step it's own step class prevents vue-match-heights from behaving buggy -->
    <HorizontalScroll 
      v-if="showCards"
      gap="22px"
      id="scroll-insurance-cards"
      v-match-heights="{el: matchHeightsElements()}"
    >
      <template
        v-for="(product, i) in products"
      >
        <InsuranceCard
          v-if="!isAevitaeBasicInsurance(product)"
          :product="product"
          :productGroupContainsFreeProduct="productGroupContainsFreeProduct"
          :amountVisibleProducts="amountVisibleProducts"
          :step="step"
          :level="currentLevel(product)"
          :key="'insurance-products-' + i"
          class="insurance-card"
          :class="{ 'first-card': i === 0, 'last-card': i === products.length -1}"
        />
        <AdvancedBasicInsuranceCard
          :product="product"
          :step="step"
          :level="{level: i+1, maxLevel: products.length}"
          v-else 
          :key="'insurance-products-' + i"
          @openHospitalOverlay="(productCode) => $emit('openHospitalOverlay', productCode)"
          @openHealthcareProviderOverlay="(productCode) => $emit('openHealthcareProviderOverlay', productCode)"
          :class="{ 'first-card': i === 0, 'last-card': i === products.length -1}"
        />
      </template>
    </HorizontalScroll>

    <slot name="disclaimer"></slot>
  </Container>
</template>

<script>
import { mapGetters } from 'vuex';
import HorizontalScroll from '@/components/shared/2023/HorizontalScroll.vue';
import InsuranceCard from '@/components/configuration/insuranceCard/InsuranceCard.vue';
import productHelper from '@/helpers/product';
import AdvancedBasicInsuranceCard from '@/components/configuration/insuranceCard/AdvancedBasicInsuranceCard.vue';
import Container from '@/components/shared/2.0-import/Container.vue';

export default {
  name: 'InsuranceGroup',
  emits: ['openHospitalOverlay', 'openHealthcareProviderOverlay'],
  components: {
    InsuranceCard,
    HorizontalScroll,
    AdvancedBasicInsuranceCard,
    Container
  },
  data() {
    return {
      showCards: true,
      aevitaeAdvancedInsuranceProductComponentCodes: [
        1114, // Bewust Verzekerd Restitutie
        1115, // Natura Select
        1117, // Natura
        1116, // Restitutie
        1118  // Bewust Verzekerd Natura
      ]
    };
  },
  props: {
    packageTypes: Array,
    step: Number
  },
  computed: {
    ...mapGetters(['productList', 'startDate']),
    products() {
        return this.productList?.filter(group => this.packageTypes.includes(group.packageType))
        ?.flatMap(group => group.products)
        .map(product => this.filterProduct(product))
        // Initial sorting, price ascending
        .sort((a,b) => productHelper.minPremium(a) - productHelper.minPremium(b))
        .sort((a, b) => {
          /** 
           * ProductComponentCode is null when it's the no insurance option
           * These products should be at the end.
          */
          if(a.productComponentCode === null || b.productComponentCode === null){
            return a.productComponentCode === null ? 1 : -1;
          }

          /**
           * Disabled products should be at the end.
           * If both are disabled, they should be sorted by price. (This is already done by the initial sorting)
          */ 
          if(a.isDisabled || b.isDisabled){
            if(a.isDisabled && b.isDisabled){
              return 0;
            }
            return a.isDisabled ? 1 : -1;
          }
        });
    },
    productGroupContainsFreeProduct() {
      return this.products.some(product => productHelper.isFree(product));
    },
    amountVisibleProducts() {
      if(this.packageTypes.includes('MB')) {
        return 3;
      }else{
        return this.$screen.lt.lg ? 3 : 4;
      }
    },
    levelIndicatorProducts(){
      return this.products.filter(x => x.productComponentCode && !productHelper.isFree(x) && x.persons.some((person) => !person.isDisabled));
    }
  },
  methods:{
    filterProduct(product){
      return {
        ...product,
        persons: productHelper.filteredPersonsForProduct(product, this.startDate),
        isDisabled: productHelper.isDisabled(product),
        hasPersons: productHelper.hasPersons(product)
        };
    },
    isAevitaeBasicInsurance(product) {
      return this.aevitaeAdvancedInsuranceProductComponentCodes.includes(parseInt(product.productComponentCode));
    },
    currentLevel(product) {
      const currentLevelProduct = this.levelIndicatorProducts.indexOf(product);

      if (currentLevelProduct >= 0){
        return {level: currentLevelProduct + 1, maxLevel: this.levelIndicatorProducts.length};   
      }
      else {
        return null;
      }
    },
    matchHeightsElements() {
      const elements = [];

      for(let j=0; j < 6; j++) {
        elements.push(`.step-${this.step} .checkmark-item-${j}`);
      }

      elements.push(`.step-${this.step} .package`
        , `.step-${this.step} .insurancecard-header`
        ,`.step-${this.step} div.usp`
        , `.step-${this.step} #card-title`);

      return elements;
    }
  },
  watch: {
    // When the step changes, we want to hide the cards and then show them again. Otherwise vue-match-heights will behave buggy.
    step() {
      this.showCards = false;
      this.$nextTick(() => {
        this.showCards = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.first-card{
  margin-left: 5px;
}
.last-card{
  margin-right: 5px;
}
.insurance-card {
  margin-top: 5px;
  margin-bottom: 5px;
}

</style>
