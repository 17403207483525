<template>
  <CloseButtonStickyHeader @close="onClose" :padded="false">
    <H1 id="title">{{ title }}</H1>
    <div
      class="d-flex" :class="{'ml-n3': !isPaddedOverlay && !$screen.xs}"
      v-if="basicReimbursementProduct"
      v-match-heights="{ el: 
        ['.header-section', '.title-section', '.card-title', '.card-price', 
         '.row0', '.row1', '.row2', '.row3', '.row4', '.row5', '.row6', 
         '.row7', '.row8', '.row9', '.row10', '.row11', '.row12', '.row13'
        ]
      }"
    >
      <CompareCard
        v-if="$screen.gt.xs"
        isHeaderColumn
        :typeOfInsurance="typeOfInsurance"
        :product="{
          reimbursementTypes: 
            filteredReimbursementTypes.map((x, i) => { return { key: i+1, value: x.categoryName } })
        }"
      />
      <HorizontalScroll :useGap="false" class="w-100 mx-0 horizontal-scroll">
        <CompareCard
          v-if="!compareProductExclusions.includes(basicReimbursementProduct.productComponentCode)"
          :product="basicReimbursementProduct"
          :amountOfCardsCompared="amountOfCardsCompared"
          :typeOfInsurance="typeOfInsurance"
          @excludeFromComparison="(id) => {compareProductExclusions.push(id); $forceUpdate()}"
        />
        <CompareCard
          v-for="(product, i) in filteredProducts"
          :key="`${i}`"
          :product="product"
          :typeOfInsurance="typeOfInsurance"
          :amountOfCardsCompared="amountOfCardsCompared"
          @excludeFromComparison="(id) => {compareProductExclusions.push(id); $forceUpdate()}"
        />
      </HorizontalScroll>
    </div>
  </CloseButtonStickyHeader>
</template>

<script>
import { mapGetters } from 'vuex';
import { orderBy } from 'lodash-es';
import CompareCard from '@/components/shared/2.0-import/CompareCard.vue';
import HorizontalScroll from '@/components/shared/2.0-import/HorizontalScroll.vue';
import CloseButtonStickyHeader from '@/components/shared/2.0-import/CloseButtonStickyHeader.vue';
import H1 from '@/components/shared/2.0-import/text/H1.vue';

export default {
  name: 'CompareDesktop',
  components: {
    H1,
    HorizontalScroll,
    CompareCard,
    CloseButtonStickyHeader
  },
  emits: ['closeOverlay'],
  props: {
    typeOfInsurance: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      compareProductExclusions: []
    };
  },
  computed: {
    ...mapGetters(['agreement', 'reimbursements', 'productList']),
    amountOfCardsCompared() {
      return this.compareProductExclusions.includes(this.basicReimbursementProduct.productComponentCode) 
        ? this.filteredProducts.length : this.filteredProducts.length + 1;
    },
    isPaddedOverlay() {
      return false;
    },
    title() {
      if (this.typeOfInsurance === 'XA' || this.typeOfInsurance === 'AE') return this.$tExistsOrDefault('calculatePremium.extraAdditionalInsurance.compareExtraAdditionalInsurance');
      if (this.typeOfInsurance === 'AP') return this.$tExistsOrDefault('calculatePremium.additionalInsurance.compareAdditionalInsurance');
      if (this.typeOfInsurance === 'TA') return this.$tExistsOrDefault('calculatePremium.dentalInsurance.compareDentalInsurance');
      return '';
    },
    filteredProducts() {
      return orderBy(
        this.insurancesToCompare.filter(
          (x) => !!x.productComponentCode 
              && !this.compareProductExclusions.includes(x.productComponentCode)),
        ['key']
      );
    },
    filteredReimbursementTypes() {
      const filteredReimbursementTypes = this.reimbursements.filter(
        (item) =>
          item.language ===
          (this.$i18n.locale() ? this.$i18n.locale().toUpperCase() : 'NL')
      );
      if (filteredReimbursementTypes.length > 0) {
        return orderBy(filteredReimbursementTypes, ['categoryId']);
      }
      return [];
    },
    basicReimbursementProduct() {
      const basicReimbursements = this.reimbursements
        .filter(
          (r) => r.language.toUpperCase() === this.$i18n.locale().toUpperCase()
        )
        .map((r) => {
          return {
            key: r.categoryId,
            value: r.basicReimbursementText,
          };
        });

      return {
        title: this.$tExistsOrDefault('calculatePremium.basicInsurance.default.title'),
        productComponentCode: 'MB',
        packageTypeForWebApplications: 'MB',
        reimbursementTypes: basicReimbursements,
      };
    },
    insurancesToCompare() {
      if (this.typeOfInsurance === 'XA' || this.typeOfInsurance === 'AE') return this.getExtraAdditionalInsurances();
      if (this.typeOfInsurance === 'AP') return this.getAdditionalInsurances();
      if (this.typeOfInsurance === 'TA') return this.getDentalInsurances();
      return [];
    },
  },
  methods: {
    onClose() {
      this.compareProductExclusions = [];
      this.$emit('closeOverlay', false);
    },
    getAdditionalInsurances() {
      if (this.productList != null) {
        let productGroups = this.productList.filter(
          (group) =>
            group.cardGroupId.includes('AP') && group.products.length > 0
        );
        let additionalInsurancesList = [];

        let packSetNr = productGroups.map((x) => x.packageSet);
        let maxPackSetNr = 1;
        if (packSetNr.length > 1) {
          maxPackSetNr = productGroups
            .map((x) => x.packageSet)
            .sort((a, b) => (a < b ? -1 : 1))[0];
        }

        let emptyProductComponent = null;
        productGroups.forEach((item) => {
          let productComponents = item.products;
          if (item.packageSet === maxPackSetNr) {
            emptyProductComponent = productComponents.find(
              (pc) => pc.productComponentCode === null
            );
          }

          productComponents = productComponents.filter(
            (pc) => pc.productComponentCode !== null
          );
          productComponents.forEach((product) => {
            additionalInsurancesList.push(product);
          });
        });

        if (emptyProductComponent != null) {
          additionalInsurancesList.push(emptyProductComponent);
        }

        return additionalInsurancesList;
      } else {
        return [];
      }
    },
    getDentalInsurances() {
      if (this.productList != null) {
        let filtered = this.productList.find(
          (group) => group.packageType === 'TA'
        );
        return filtered ? filtered.products : [];
      } else {
        return [];
      }
    },
    getExtraAdditionalInsurances() {
      if (this.productList != null) {
        let filtered = this.productList.find(
          (group) => group.packageType === 'XA' || group.packageType === 'AE'
        );
        return filtered ? filtered.products : [];
      } else {
        return [];
      }
    },
  },
  beforeDestroy() {
    this.compareProductExclusions = [];
  }
};
</script>

<style lang='scss' scoped>
#title{
  margin-left:40px;
  margin-bottom:25px;
}
</style>
