<template>
  <div class="image-container">
    <div class="score-absolute">{{ score }}</div>
    <img src="/images/zorgkiezer-score.png" :alt="'zorgkiezer-score-' + score"/>
  </div>  
</template>

<script>
export default {
  name: 'Zorgkiezer',
  props: {
    score: {
      value: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
}

.score-absolute {
  position: absolute;
	top: 3px;
  right: 5px;
	pointer-events: none;
  font-size: 33px;
  font-weight: bold;
  color: white;
}

</style>