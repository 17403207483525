<template>
  <div>
    <MostChosenIndicator :class="{'invisible': !isNatura}" id="most-chosen-indicator" />
    <RectangleWithShadow id="card" :padded="false">
      <LoadingOverlay :showOverlay="isBusinessRulePostLoading" class="position-relative">
        <!-- Giving each step it's own step class prevents vue-match-heights from behaving buggy -->
        <div
          id="card-content"
          :class="`step-${step}`"
        >
          <InsuranceCardHeader
            :title="title"
            :subtitle="subtitle"
            :level="level"
            :product="product"
          />
          <CardHorizontalRule />
          <div class="usp">
            <div class="text-center" id="urgent-care">
              <PlainText class="mb-2">{{ $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.urgentCare.textPrimary1`) }}</PlainText>
              <PlainText type="positive">{{ $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.urgentCare.textPositive`) }}</PlainText>
              <PlainText>{{ $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.urgentCare.textPrimary2`) }}</PlainText>
            </div>
            <CardHorizontalRule />
            <div id="plannable-care" class="text-center">
              <div>
                <PlainText class="d-inline">{{ $tExistsOrDefault('calculatePremium.basicInsurance.advancedBasicInsuranceCards.shared.reimbursementsOverlay.textPrimary') }}</PlainText>
                <Tooltip class="d-inline">
                  <LinkText class="d-inline">{{ $tExistsOrDefault('calculatePremium.basicInsurance.advancedBasicInsuranceCards.shared.reimbursementsOverlay.linkText') }}</LinkText>
                  <template #content>
                    <PlainText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.advancedBasicInsuranceCards.shared.reimbursementsOverlay.tooltipText') }}</PlainText>
                  </template>
                </Tooltip>
              </div>
              <section id="hospital-care" class="care-type">
                <PlainText type="secondary" class="care-type-title">{{ $tExistsOrDefault('calculatePremium.basicInsurance.advancedBasicInsuranceCards.shared.hospitalCareTitle') }}</PlainText>
                <div class="care-type-description">
                  <PlainText class="d-inline" type="positive">{{ $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.planableCare.hospitalCare.textPositive`) }}</PlainText>
                  <PlainText class="d-inline">{{ $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.planableCare.hospitalCare.textPrimary`) }}</PlainText>
                  <PlainText class="d-inline" type="warning">{{ $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.planableCare.hospitalCare.textWarning`) }}</PlainText>
                </div>
                <LinkText :class="{'invisible': isRestitutie}" @click.native="$emit('openHospitalOverlay',productCode)">{{ isRestitutie ? '_' : $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.planableCare.hospitalCare.linkText`) }}</LinkText>
              </section>
              <section id="non-hospital-care" class="care-type">
                <PlainText type="secondary" class="care-type-title">{{ $tExistsOrDefault('calculatePremium.basicInsurance.advancedBasicInsuranceCards.shared.nonHospitalCareTitle') }}</PlainText>
                <div class="care-type-description">
                  <PlainText class="d-inline" type="positive">{{ $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.planableCare.nonHospitalCare.textPositive`) }}</PlainText>
                  <PlainText class="d-inline">{{ $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.planableCare.nonHospitalCare.textPrimary`) }}</PlainText>
                  <PlainText class="d-inline" type="warning">{{ $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.planableCare.nonHospitalCare.textWarning`) }}</PlainText>
                </div>
                <LinkText :class="{'invisible': isRestitutie}" @click.native="$emit('openHealthcareProviderOverlay', productCode)">{{ isRestitutie ? '_' : $tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${product.productComponentCode}.planableCare.nonHospitalCare.linkText`) }}</LinkText>
              </section>
            </div>
            <CardHorizontalRule />
            <div id="turnover-ceiling">
              <div class="bulletpoint-wrapper">
                <Check />
                <PlainText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.advancedBasicInsuranceCards.shared.turnoverCeiling.textPrimary') }}</PlainText>
              </div>
              <PlainText type="secondary" id="turnover-ceiling-explanation">{{ $tExistsOrDefault('calculatePremium.basicInsurance.advancedBasicInsuranceCards.shared.turnoverCeiling.textSecondary') }}</PlainText>
            </div>
          </div>
          <CardHorizontalRule />
          <div>
            <PlainText>{{ $tExistsOrDefault('calculatePremium.general.selectThisPackageFor') }}</PlainText>
          </div>
          <SelectProductForPersonCheckbox
            v-for="(person) in product.persons"
            :key="`select-box-for-${product.productComponentCode}-${person.id}`"
            :product="product"
            :person="person"
            :showTooltip="false"
          />
          <CardHorizontalRule />
          <div>
            <DocumentDownloads
              size="2x"
              :product="product"
            /> 
          </div>
        </div>
      </LoadingOverlay>
    </RectangleWithShadow>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DocumentDownloads from '@/components/configuration/insuranceCard/DocumentDownloads';
import LoadingOverlay from '@/components/shared/LoadingOverlay.vue';
import RectangleWithShadow from '@/components/shared/RectangleWithShadow.vue';
import InsuranceCardHeader from '@/components/configuration/insuranceCard/InsuranceCardHeader.vue';
import CardHorizontalRule from '@/components/configuration/insuranceCard/CardHorizontalRule.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import Check from '@/assets/icons/Check.vue';
import LinkText from '@/components/shared/2.0-import/text/LinkText.vue';
import SelectProductForPersonCheckbox from '@/components/configuration/SelectProductForPersonCheckbox';
import MostChosenIndicator from '@/components/configuration/insuranceCard/MostChosenIndicator.vue';
import Tooltip from '@/components/shared/2.0-import/Tooltip.vue';

export default {
  name: 'AdvancedBasicInsuranceCard',
  emits: ['openHospitalOverlay', 'openHealthcareProviderOverlay'],
  components: {
    DocumentDownloads,
    LoadingOverlay,
    RectangleWithShadow,
    InsuranceCardHeader,
    CardHorizontalRule,
    PlainText,
    Check,
    LinkText,
    SelectProductForPersonCheckbox,
    MostChosenIndicator,
    Tooltip
  },
  data() {
    return {
    };
  },
  props: {
    product: {
      type: Object,
      required: true	
    },
    step: {
      type: Number,
      required: true
    },
    level: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isBusinessRulePostLoading']),
    isRestitutie() {
      return this.product.productComponentCode === '1116' || this.product.productComponentCode === '1114';
    },
    isNatura() {
      return this.product.productComponentCode === '1117' || this.product.productComponentCode === '1118';
    },
    isC4LCard() {
      return this.product.productComponentCode === '1114' || this.product.productComponentCode === '1118'
    },
    productCode(){
      return parseInt(this.product.productComponentCode);
    },
    title() {
      return this.isC4LCard ? this.product.title : this.$tExistsOrDefault(`calculatePremium.basicInsurance.advancedBasicInsuranceCards.${this.product.productComponentCode}.title`)
    },
    subtitle() {
      return this.isC4LCard ? null : this.product.title;
    }
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
#card-content {
    width: 263px;
    padding: 24px;
}
.bulletpoint-wrapper{
  display: flex;
  flex-direction: row;
  gap: 8px;
  :first-child{
    flex-shrink: 0;
    height: 22px;
  }
}

#turnover-ceiling-explanation{
  margin-left: 28px;
}

.care-type{
  margin-top: 25px;
  margin-bottom: 32px;
  .care-type-title{
  border: 1px solid var(--color-grey-400);
  margin-bottom: 6px;
  }
.care-type-description{
    margin-bottom: 3px;
    height: 44px;
  }
}

#most-chosen-indicator{
  position:relative;
  top: 8px;
}
</style>